import React, { Component, PropTypes } from 'react';
import '@opentok/client';
import axios from 'axios';
import {
  OTSession,
  OTPublisher,
  // OTStreams,
  OTSubscriber,
  createSession,
  OTStreams
} from 'opentok-react';
import { getApiURL } from '../Config';

class ChatSessionMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sessionId: '',
      name: '',
      uid: 0,
      expertFlg: '',
      apiKey: '',
      token: '',
      apiInfo: {},
      credentialsPresent: false,
      streams: [],
      publisherProperties: {
        name: 'Unknown User',
        height: '25%'
      },

      archiving: {
        status: false,
        buttonText: 'Start Archiving',
        guid: 'NA',
        returnMessage: 'NA',
        apiReturn: {}
      },
      expertUserId: 8,
      debuggingDiv: false
    };

    this.publisherProperties = {
      showControls: true,
      name: 'My Stream'
    };
    this.publisherEventHandlers = {
      streamCreated: event => {
        console.log('Publisher stream created!');
        // alert('In streamCreated');
      },
      streamDestroyed: event => {
        console.log('Publisher stream destroyed!');
        // alert('In streamDestroyed');
      }
    };

    this.sessionEventHandlers = {
      archiveStarted: event => {
        console.log('event handler archive started');
      },
      archiveStopped: event => {
        console.log('event handler archive stopped');
      }
    };
  }

  componentWillMount() {
    const { session_id, apiKey, token } = this.props.match.params;
    //debugger;
    this.sessionHelper = createSession({
      apiKey: apiKey,
      sessionId: session_id,
      token: token,
      onStreamsUpdated: streams => {
        this.setState({ streams });
      }
    });
  }
  componentDidMount() {
    const { session_id } = this.props.match.params;
    console.log('In componentDidMount');
    var loggedOn = [];
    loggedOn = atob(sessionStorage.getItem('loggedOn')).split(',');
    this.setState({ publisherProperties: { name: loggedOn[0] } });
    this.setState({ name: loggedOn[0] });
    this.publisherProperties.name = loggedOn[0];
    //debugger;

    console.log('In componentDidMount');

    // console.log('Incoming session id: ' + session_id);
    var chatSessionInfo = {};
    chatSessionInfo = JSON.parse(sessionStorage.getItem(session_id));
    console.log('chatSessionInfo');
    console.log(chatSessionInfo);
    // get apiKey, Token, etc.
    this.getApiData(chatSessionInfo);
  }

  getApiData = async chatSessionInfo => {
    const appointmentURI =
      getApiURL() + '/tbsession/' + chatSessionInfo.session_id + '/session';
    axios
      .get(appointmentURI, { timeout: 15000 })
      .then(res => {
        console.log('Return From API Call');
        console.log(res.data);
        const incoming = res.data[0];
        //debugger;
        const apiInfo = {
          // apiKey: incoming.apiKey,
          // session_id: incoming.session_id,
          // token: incoming.token,
          // apiKeyInt: incoming.apiKeyInt
        };
        //debugger;
        this.setState({
          apiKey: incoming.apiKey,
          sessionId: incoming.session_id,
          uid: chatSessionInfo.uid,
          expertFlg: chatSessionInfo.expert_flg,
          token: incoming.token,
          expertUserId: chatSessionInfo.expert_user_id
        });
        //debugger;
        this.setState({ apiInfo: apiInfo, credentialsPresent: true });

        //debugger;
      })
      .catch(function(error) {
        console.log(error);
        return 'Error Getting Session Info';
      });
    console.log('Finished running getApiData()');

    return 'Obtained Session Info';
  };

  archivingToggle = () => {
    // call kaigie api first

    //var current_url = window.location.href.toString();
    const { session_id } = this.props.match.params;
    var kaigieSessionAPI = getApiURL() + '/archiveprocess/';
    //debugger;
    var archiving_action = this.state.archiving.status ? 'stop' : 'start';

    var archiving_info = session_id + ',' + archiving_action;
    var incomingArchiveAction = archiving_action;
    //debugger;
    if (archiving_action === 'stop') {
      //var guid_div = $('archive_guid_div');

      var archive_guid = this.state.archiving.guid;

      archiving_info += ',' + archive_guid;
    }

    const archiving_param = btoa(archiving_info);
    kaigieSessionAPI += archiving_param;
    //debugger;
    axios
      .get(kaigieSessionAPI)
      .then(response => {
        var res = response.data[0];
        //debugger;
        var newStatus = !this.state.archiving.status;
        // this.setState({
        //   archiving: { status: newStatus, buttonText: newButtonText }
        // });
        this.setState({
          archiving: {
            guid: res.archive_guid,
            apiReturn: res,
            returnMessage: res.archive_status,
            status: newStatus,
            buttonText: newButtonText
          }
        });
        //check this.state.archiving
      })
      .catch(error => {
        //debugger;
        console.log('error in api call');
      });

    var newButtonText = '';
    //might be better to use incomingArchiveAction instead
    if (this.state.archiving.status === true) {
      //console.log('Archiving Started');
      newButtonText = 'Start Archiving';
      // debugger;
      // this.sendArchiveEmail(incomingArchiveAction);
    } else {
      //  console.log('Archiving Stopped');
      newButtonText = 'Stop Archiving';
      // debugger;
      // this.sendArchiveEmail(incomingArchiveAction);
    }
    //debugger;
    this.sendArchiveEmail(incomingArchiveAction);
  };

  sendArchiveEmail = incomingAction => {
    //start if here

    if (incomingAction === 'stop') {
      //debugger;
      const { session_id } = this.props.match.params;
      var currentArchive = {
        session_id: session_id,
        archive_guid: this.state.archiving.guid,
        user_screen_name: 'Unknown User Name',
        text_content: 'NA',
        base_domain: 'www.kaigie.com',
        expert_user_id: this.state.expertUserId,
        mode: 'remote'
      };
      //debugger;
      axios
        .post(getApiURL() + '/archiveemail', currentArchive)
        .then(response => {
          //debugger;
          console.log(response);
        })
        .catch(error => {
          //debugger;
          console.log('error in api call');
        });
    }
  };

  render() {
    const { session_id, apiKey, token } = this.props.match.params;

    return (
      <div className="chat-element">
        <div id="componentRow" className="row justify-content-center">
          <div>Chat Session Mobile</div>
          <div className="w-100 clearfix" />

          <div id="videoContainerDiv">
            <div id="chatDiv">
              <div id="videoComponents">
                {this.state.credentialsPresent && (
                  <OTSession
                    apiKey={apiKey}
                    sessionId={session_id}
                    token={token}
                    eventHandlers={this.sessionEventHandlers}
                    id={token}
                  >
                    <div>
                      <div>
                        <div className="row video-row">
                          <div
                            id="publisherCol"
                            className="p-3 m-3 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 video-col panel-background-5"
                          >
                            <div className="card video-card ">
                              <div className="card-body">
                                <OTPublisher
                                  properties={this.state.publisherProperties}
                                  eventHandlers={this.publisherEventHandlers}
                                />

                                <div>
                                  {/* insert here */}
                                  {this.state.expertFlg === 'Y' && (
                                    <button
                                      id="archivingButton"
                                      className={
                                        'btn btn-sm ' +
                                        (this.state.archiving.status
                                          ? 'btn-danger '
                                          : 'btn-success') +
                                        ' m-1 p-1 kaigie-btn'
                                      }
                                      onClick={this.archivingToggle}
                                    >
                                      {this.state.archiving.buttonText}
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            id="subscriberCol"
                            className="p-3 m-3 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 video-col panel-background-5"
                          >
                            <div className="card video-card">
                              <div className="card-body">
                                {this.state.streams.map(stream => {
                                  return (
                                    <OTStreams>
                                      <OTSubscriber
                                        key={sessionStorage.getItem(
                                          'currentUser'
                                        )}
                                      />
                                    </OTStreams>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </OTSession>
                )}

                <div className="clearfix p-2" />
              </div>
            </div>
          </div>
        </div>
        {this.state.expertFlg === 'Y' && this.state.debuggingDiv && (
          <div id="sessionInfoDiv" className="p-3">
            <div>Debugging Info</div>
            <div className="clearfix" />
            <div>Archive GUID: {this.state.archiving.guid}</div>
            <div className="clearfix" />
            <div>Archiving Result: {this.state.archiving.returnMessage}</div>
          </div>
        )}
      </div>
    );
  }
}
// var videoContainerStyle = {
//   width: '90%',
//   marginLeft: 'auto',
//   marginRight: 'auto'
// };

export default ChatSessionMobile;
