import React, { Component } from 'react';
//HashRouter instead of BrowserRouter for IIS - guess it's a plain file server
import { HashRouter, Route, withRouter } from 'react-router-dom';
import './App.css';
import Logon from './components/Logon';
import Heading from './components/common/Heading';
import DebugDiv from './components/common/DebugDiv';
import AppointmentList from './components/AppointmentList';
import ChatSession2 from './components/ChatSession2';
import ChatSessionMainSite from './components/ChatSessionMainSite';
import ChatSessionMobile from './components/ChatSessionMobile';
import KaigieRedirector from './components/KaigieRedirector';
import RedirectApptList from './components/RedirectApptList';
import Testing from './components/Testing';
import FlowTest from './components/FlowTest';
import What from './components/What';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideHeading: false
    };
    var hideHeadingDuringChat = this.hideHeadingDuringChat.bind(this);
    console.log(this.props);
  }
  ComponentToHide = props => {
    const { location } = props;
    debugger;
    if (
      location.pathname.match(/chatsessionmainsite/) ||
      location.pathname.match(/chatsession2/)
    ) {
      return null;
    }

    return <Heading />;
  };

  hideHeadingDuringChat(isHidden) {
    // alert('hideHeadingDuringChat executed');
    this.setState({ hideHeading: isHidden });
  }

  componentWillMount() {
    //sessionStorage.clear();
  }

  render() {
    var hideHeadingDuringChat = this.hideHeadingDuringChat.bind(this);
    const pathName = window.location.pathname;
    var chatPage = false;
    if (pathName.indexOf('chatsession') > -1) {
      chatPage = true;
    }
    // const blahBlah = withRouter(this.ComponentToHide);
    return (
      <div className="App chat-element">
        {/* {!this.state.hideHeading && <Heading />} */}

        {/* {blahBlah}
        {this.noHeadingInChat()}; */}
        {/* {!chatPage && <Heading />} */}

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 col-lg-6 col-xl-6 mt-2">
              <DebugDiv />
            </div>
          </div>
        </div>
        <div className="container">
          {/* basename="/rvc" */}
          {/* //basename included in homepage in package.json */}
          <HashRouter>
            <Route exact path="/" component={Logon} />
            {/* <Route path="/appointments" component={AppointmentList} /> */}
            <Route
              path="/appointments"
              component={() => (
                <AppointmentList
                  hideHeadingDuringChat={hideHeadingDuringChat.bind(this)}
                />
              )}
            />
            <Route
              path="/redirectapptlist/:userId/:userName"
              component={RedirectApptList}
            />

            <Route
              path="/chatsession2/:session_id/:apiKey/:token"
              component={ChatSession2}
            />
            <Route
              path="/chatsessionmainsite/:userName/:apiKey/:session_id/:token"
              component={ChatSessionMainSite}
            />
            <Route
              path="/chatsessionmobile/:session_id/:apiKey/:token"
              component={ChatSessionMobile}
            />
            <Route
              path="/kaigieredirector/:session_id/:id/:uid/:user_screen_name/:expert_flg/:expert_user_id"
              component={KaigieRedirector}
            />
            <Route path="/testing" component={Testing} />
            <Route path="/flowtest" component={FlowTest} />
            <Route path="/what" component={What} />
          </HashRouter>
        </div>
      </div>
    );
  }
}

export default App;
